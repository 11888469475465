import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import config from '~/../site-config.js'

interface MetadataProps {
  viewport?: string | null
  robots?: string | null
  robotsNoIndex?: boolean
  ogSiteName?: string | null
  ogTitle?: string | null
  ogDescription?: string | null
  ogUrl?: string | null
  ogImage?: string | null
  ogType?: string | null
  articlePublishedTime?: string | null
  articleModifiedTime?: string | null
  articleExpirationTime?: string | null
  articleAuthor?: string | null
  articleSection?: string | null
  articleTag?: string | null
  twitterTitle?: string | null
  twitterDescription?: string | null
  twitterImage?: string | null
  itemListElement1Name?: string | null
  itemListElement1Item?: string | null
  itemListElement1Position?: string | null
  itemListElement2Name?: string | null
  itemListElement2Item?: string | null
  itemListElement2Position?: string | null
  itemListElement3Name?: string | null
  schemaType?: string | null
  schemaImage?: string | null
  schemaUrl?: string | null
  schemaSameAs?: string | null
  schemaLogo?: string | null
  schemaName?: string | null
  schemaDescription?: string | null
  schemaEmail?: string | null
  showBreadcrumbs?: boolean
}

interface Props {
  title?: string
  description?: string | null
  canonical?: string | null
  pageMetaData?: MetadataProps
}

const Metadata: React.FC<Props> = ({
  title: metaTitle = config.siteMetadata.title,
  description = 'Why risk it? Know before you buy. Avoid buying a home with costly hidden problems and repairs by putting it under the PropertyLens!',
  canonical,
  pageMetaData,
}) => {
  const { asPath } = useRouter()
  const metaUrl = new URL(asPath, config.siteMetadata.siteUrl).toString()
  const metaImage = config.siteMetadata.image
  const metaDescription = description
    ? description
    : `${metaTitle} | PropertyLens®`

  if (canonical?.startsWith('/')) {
    canonical = new URL(canonical, config.siteMetadata.siteUrl).toString()
  }

  const {
    viewport,
    robots,
    robotsNoIndex,
    ogSiteName,
    ogTitle,
    ogDescription,
    ogUrl,
    ogImage,
    ogType,
    articlePublishedTime,
    articleModifiedTime,
    articleExpirationTime,
    articleAuthor,
    articleSection,
    articleTag,
    twitterTitle,
    twitterDescription,
    twitterImage,
    itemListElement1Name,
    itemListElement1Item,
    itemListElement2Name,
    itemListElement2Item,
    itemListElement3Name,
    showBreadcrumbs
  } = pageMetaData ?? {}

  const breadcrumbList = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      { name: itemListElement1Name, item: itemListElement1Item },
      { name: itemListElement2Name, item: itemListElement2Item },
      { name: itemListElement3Name },
    ]
      .filter((item) => item.name) // Only include items with names
      .map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: item.name,
        ...(item.item && { item: item.item }),
      })),
  }

  return (
    <>
    <Head>
      <title>{metaTitle} | PropertyLens®</title>
      <meta name="description" content={metaDescription} />
      <meta name="viewport" content={viewport ?? "width=device-width, initial-scale=1.0"} />
      <meta name="robots" content={robotsNoIndex ? 'noindex, nofollow' : robots ?? "max-image-preview:large"} />

      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content={ogSiteName ? ogSiteName : 'PropertyLens®'} />
      <meta property="og:title" content={ogTitle ? ogTitle : metaTitle + ' | PropertyLens®'} />
      <meta property="og:description" content={ogDescription ? ogDescription : metaDescription} />
      <meta property="og:url" content={ogUrl ? ogUrl : metaUrl} />
      <meta property="og:image" content={ogImage ? ogImage : metaImage} />
      <meta property="og:type" content={ogType ? ogType : "website"} />

      { (articleAuthor || articlePublishedTime) && (
        <>
          <meta property="article:published_time" content={articlePublishedTime} />
          <meta property="article:modified_time" content={articleModifiedTime} />
          <meta property="article:expiration_time" content={articleExpirationTime} />
          <meta property="article:author" content={articleAuthor} />
          <meta property="article:section" content={articleSection} />
          <meta property="article:tag" content={articleTag} />
        </>
      )}

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content="@mypropertylens" />
      <meta property="twitter:creator" content="@mypropertylens" />

      <meta property="twitter:title" content={twitterTitle ? twitterTitle : ogTitle ? ogTitle : (metaTitle  + ' | PropertyLens®')} />
      <meta property="twitter:description" content={twitterDescription ? twitterDescription : ogDescription ? ogDescription : metaDescription} />
      <meta property="twitter:image" content={twitterImage ? twitterImage : ogImage ? ogImage : metaImage} />

      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href="/favicon/apple-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="/favicon/apple-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="/favicon/apple-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="/favicon/apple-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/favicon/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/favicon/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/favicon/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/favicon/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/favicon/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon/favicon-16x16.png"
      />

      {canonical && <link rel="canonical" href={canonical} />}
    </Head>
    {showBreadcrumbs && (
      <Script
          id="breadcrumbs"
          strategy="afterInteractive"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(breadcrumbList),
          }}
        />
      )}
    </>
  )
}

export default Metadata
